import Dashboard from "../../Components/Dashboard/Dashboard";
import MachineCategory from "../../Components/MachineCategory/MachineCategory";
import Machine from "../../Components/Machine/Machine";
import UOMMaster from "../../Components/UOM/UOMMaster";
import Tooltypes from "../../Components/ToolTypes/Tooltypes";
import UserType from "../../Components/UserType/UserType";
import ToolMaterial from "../../Components/ToolMaterial/ToolMaterial";
import UserMaster from "../../Components/UserMaster/UserMaster";
import Hardness from "../../Components/Hardness/Hardness";
import ToolCategory from "../../Components/ToolCategory/ToolCategory";
import ToolSubCategory from "../../Components/ToolSubCategory/ToolSubCategory";
import WorkPieceMaterialClassfication from "../../Components/WorkPieceMaterialClassfication/WorkPieceMaterialClassfication";
import WorkPieceMaterial from "../../Components/WorkPieceMaterial/WorkPieceMaterial";
import ToolMapping from "../../Components/Tool Mapping/ToolMapping";
import MachineDimParameter from "../../Components/MachineDimParameter/MachineDimParameter";
import ToolingParameter from "../../Components/ToolingParameter/ToolingParameter";
import Product from "../../Components/Product/List";
import ActionProduct from "../../Components/Product/Action";
import CommonAttribute from "../../Components/Common Attribute/CommonAttribute";
import  Leads  from "../../Components/Leads/Leads";
import Subcription from "../../Components/Subcription/Subcription";
// import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

const routes = [
    { path: `${process.env.PUBLIC_URL}/dashboard`, element: Dashboard, exact: true },

    { path: `${process.env.PUBLIC_URL}/machine_category`, element: MachineCategory, exact: true },
    { path: `${process.env.PUBLIC_URL}/machine_list`, element: Machine, exact: true },
    { path: `${process.env.PUBLIC_URL}/uom_list`, element: UOMMaster, exact: true },
    { path: `${process.env.PUBLIC_URL}/tooltype_list`, element: Tooltypes, exact: true },
    { path: `${process.env.PUBLIC_URL}/usertype_list`, element: UserType, exact: true },
    { path: `${process.env.PUBLIC_URL}/tool_material_list`, element: ToolMaterial, exact: true },
    { path: `${process.env.PUBLIC_URL}/user_list`, element: UserMaster, exact: true },
    { path: `${process.env.PUBLIC_URL}/hardness`, element: Hardness, exact: true },
    { path: `${process.env.PUBLIC_URL}/tool_category_list`, element: ToolCategory, exact: true },
    { path: `${process.env.PUBLIC_URL}/tool_subcategory_list`, element: ToolSubCategory, exact: true },
    { path: `${process.env.PUBLIC_URL}/work_piece_material_classification_list`, element: WorkPieceMaterialClassfication, exact: true },
    { path: `${process.env.PUBLIC_URL}/work_piece_material_list`, element: WorkPieceMaterial, exact: true },
    { path: `${process.env.PUBLIC_URL}/tool_type_mapping`, element: ToolMapping, exact: true },
    { path: `${process.env.PUBLIC_URL}/machine_dim_parameter_list`, element: MachineDimParameter, exact: true },
    { path: `${process.env.PUBLIC_URL}/tooling_parameter_list`, element: ToolingParameter, exact: true },
    { path: `${process.env.PUBLIC_URL}/products`, element: Product, exact: true },
    { path: `${process.env.PUBLIC_URL}/product/:uuid?`, element: ActionProduct, exact: false }, 
    { path: `${process.env.PUBLIC_URL}/common_attribute`, element: CommonAttribute, exact: false },
    { path: `${process.env.PUBLIC_URL}/lead_list`, element: Leads, exact: false },
    { path: `${process.env.PUBLIC_URL}/subcription`, element: Subcription, exact: false },
]

export default routes;