import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";

const schema = yup
  .object()
  .shape({
    value: yup.string().required("Key  name is  required !"),
    keyvalue: yup
    .string()
    .required("Enter key value is requried !"),     
  })
  .required();

export const Addtablelable = ({
  visible,
  setVisible,
  table_label,
  setTable_lables,
}) => {

  const onSubmit = (data) => {
    // console.log("submitted Data", data);
      //  console.log("table",table_label);

       
       let check=table_label.filter((item) => item["value"]== data.value); 
       console.log("che",check)
    if (check.length==0) {
        let obj={}
           obj.key="label"; 
           obj.value=data.value; 
           obj.text_value=data.keyvalue;
      const arr = [...table_label, obj];
      // console.log("arr",arr)
   
      setVisible(false);
      setTable_lables(arr);
      reset();
    } else {
      toast.error("The key is already present !", {
        autoClose: 2000,
      });
    }
  };

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;
  return (
    <div>
      {/* <ToastContainer /> */}
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"} >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Add Configuration"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4"
              >
                {"Key Name"}
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="value"
                  name="value"
                  placeholder="Enter key name "
                  {...register("value")}
                />
                <span className="text-danger">{errors.value?.message}</span>
              </div>
            </div>

            <div className="form-group row mt-2">
              <label
                className="form-check-label lbl-style inter col-4"
              >
                {"Key Value"}
                <span className="text-danger">
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
               <div className="col-md-8">
               <input
                 type="text"
                 className="form-control form-control-sm"
                 id="keyvalue"
                 name="keyvalue"
                 placeholder="Enter key value "
                 {...register("keyvalue")}
               />
                 <span className="text-danger">
                  {errors.keyvalue?.message}
                </span>
             </div>
              
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow inter-bold"
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
