import React, { useEffect, useState } from "react";
import {
  get_subcription_plan_api,
  create_subcription_plan_api,
  update_subcription_plan_api,
  delete_subcription_plan_api,
} from "../../Common/API/index";
import { Menu, Plus, Pencil, Trash2 ,Edit2} from "lucide-react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import Swal from "sweetalert2";
import { calMaxPage } from "../../Common/Function/CommonFun";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faBan,
  faFilter,
  faCog,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Addtablelable } from "./Addtablelable";
const schema = yup
  .object({
    plan_name: yup
      .string()
      .max(200, "Maximum 200 characters allowed !")
      .required("Enter plan name !"),
    plan_tenure: yup
      .string()
      .required("Enter plan tenure !")
      .matches(/^\d+$/, "Only numbers are allowed !")
      .max(10, "Maximum 10 characters allowed !"),
  })
  .required();

function Subcription() {
  const [visible, setVisible] = useState(false);
  const [action, setAction] = useState({ state: "create", data: {} });
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
    maxPage: 0,
  });
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [table_label, setTable_lables] = useState([]);
  const [editedKey2, setEditedKey2] = useState("");
  const [editedValue2, setEditedValue2] = useState({});
  const [visible2, setVisible2] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [filterConfig, setFilterConfig] = useState({
    searchVal: "",
  });

  function clearFilter() {
    setFilterConfig({
      ...filterConfig,
      searchVal: "",
    });
    setSrNo(0);
    setPage(1);
  }
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  });

  const handleEdit2 = (ind) => {
    console.log("ind", ind);
    setEditedKey2(ind);
    setEditedValue2(table_label[ind]);
  };
  const handleDelete2 = (ind) => {
    table_label.splice(ind, 1);

    setTable_lables([...table_label]);
  };

  const handleInputChange2 = (event) => {
    console.log("key", event.target.checked);

    
      let obj = { ...editedValue2, text_value: event.target.value };
      setEditedValue2(obj);
     

    // setEditedValue2(event.target.value);
  };

  const handleUpdate2 = (ind) => {
    console.log("key", ind);
    let arr = [...table_label];
    console.log("edi", editedValue2);
    arr[ind] = editedValue2;

    setTable_lables(arr);
    // setLabels(updatedLabels);
    setEditedKey2("");
    setEditedValue2([]);
  };
  const onSubmit = (data) => {
    console.log(data);

    let obj={}
    table_label.forEach((item)=>{
      let ob={

        [`${item.value}`]: item.flag==null?item.text_value:item.flag,
      };
      obj={
        ...obj, ...ob
      }
       
    });
    console.log("obje",obj)

    if (action.state == "create") {
        const create_payload={
            ...data, 
            plan_configuration:[obj]
        }
      create_subcription_plan_api(create_payload).then(
        (res) => {
          console.log("res", res);
          toast.success("Subcription created successfully !",{autoClose:2000});
          setVisible(false);
          reset();
          setTable_lables([])
          fetchSubcriptionList(1, entriesPerPage);
        },
        (err) => {
          console.log("err", err);
          toast.error(err.response.data.message);
        }
      );
    } else {
      const payload = {
        plan_id: action.data.plan_id,
        plan_name:data.plan_name,
        plan_tenure:data.plan_tenure,
        plan_configuration:[obj]
      };

      update_subcription_plan_api(payload).then(
        (res) => {
          console.log("res", res);
          toast.success("Subcription updated successfully !",{autoClose:2000});
          setVisible(false);
          reset();
          setTable_lables([])
          fetchSubcriptionList(1, entriesPerPage);
        },
        (err) => {
          console.log("err", err);
          toast.error(err.response.data.message);
        }
      );
    }
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchSubcriptionList(1, entriesPerPage);
    }, 1000);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, []);

  function fetchSubcriptionList(pg, epp) {
    setDataList({ ...dataList, loading: true });

    const payload = {
      limit: epp,
      page: pg,
    };

    get_subcription_plan_api(payload).then(
      (res) => {
        console.log("res", res);
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
          maxPage: calMaxPage(res.data.total),
        });
      },
      (err) => {
        console.log("err", err);
        toast.error(err.response.data.message);
        setDataList({ ...dataList, loading: false, data: [] });
      }
    );
  }

  const delHardness = (val) => {
    Swal.fire({
      title: "Are you sure to delete",
      text: val.hardness_name,
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        delete_subcription_plan_api({ plan_id: val.plan_id }).then(
          (res) => {
            toast.success(`${val.plan_name} deleted successfully`);

            if (dataList.data.length == 1 && page > 1) {
              fetchSubcriptionList(page - 1, entriesPerPage);

              setPage(page - 1);
              setSrNo((prevC) =>
                page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
              );
            } else {
              fetchSubcriptionList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error(err.response.data.message);
          }
        );
      }
    });
  };

  function openNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Addtablelable
        visible={visible2}
        setVisible={setVisible2}
        table_label={table_label}
        setTable_lables={setTable_lables}
      />

      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        size={"lg"}
        backdrop="static"
      >
        <ModalHeader className="modalHeader" closeButton>
          <Modal.Title>
            {" "}
            {`${action.state == "create" ? "Create" : "Update"} Subcription`}
          </Modal.Title>
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <label className="form-label" htmlFor="plan_name">
                  Plan Name
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="plan_name"
                  className={`form-control ${errors.plan_name && "is-invalid"}`}
                  placeholder="Enter Plan Name"
                  {...register("plan_name")}
                />

                {errors.plan_name && (
                  <span className="text-danger">
                    {errors.plan_name?.message}
                  </span>
                )}
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label className="form-label" htmlFor="plan_tenure">
                  Plan Tenure (In Months)
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="plan_tenure"
                  className={`form-control ${
                    errors.plan_tenure && "is-invalid"
                  }`}
                  placeholder="Enter plan_tenure"
                  {...register("plan_tenure")}
                />

                {errors.plan_tenure && (
                  <span className="text-danger">
                    {errors.plan_tenure?.message}
                  </span>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-2 ms-3" style={{ fontSize: "24px" }}>
                Plan Configuration
              </p>
              <button
                className="btn btn-blue  px-4 me-3 float-end"
                onClick={(e) => {
                    e.preventDefault(); 
                  setVisible2(true);
                  console.log("clicked")
                }}
              >
                <FontAwesomeIcon
                  style={{ color: "#fff" }}
                  icon={faPlus}
                  size="sm"
                />{" "}
                {"Add Configuration"}
              </button>
            </div>

            <hr />
            <table className="table table-striped table-sm" width={"100%"}>
              <thead className="table-grey text-ceter inter">
                <tr>
                  {/* <th>Table Label Key</th> */}
                  <th>Key</th>
                  <th>Value</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {table_label.map((item, ind) => {
                    return (
                      <tr>
                        <td>{item.value}</td>
                     
                         <td>
                        {editedKey2 === ind ? (
                          <input
                            value={editedValue2.text_value}
                            onChange={(e) => handleInputChange2(e)}
                          />
                        ) : (
                          item.text_value
                        )}
                      </td>
                      <td>
                      {editedKey2 === ind ? (
                        <button
                          className="btn btn-yellow px-3 btn-sm"
                          onClick={() => handleUpdate2(ind)}
                        >
                          Update
                        </button>
                      ) : (
                        <React.Fragment>
                          <div className="editIcon me-2">
                            <Edit2
                              color="#FFFFFF"
                              size={18}
                              className="cursor-pointer"
                              onClick={() => handleEdit2(ind)}
                            />
                          </div>

                          <div
                            className="deleteIcon me-2"
                            onClick={() => handleDelete2(ind)}
                          >
                            <Trash2 color="#FFFFFF" size={18} />
                          </div>
                        </React.Fragment>
                      )}
                    </td>
                      </tr>
                     
                    );
                  })}
              </tbody>
            </table>
          </form>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-yellow px-3"
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </button>
        </ModalFooter>
      </Modal>
      <div className="min-vh-100 " id="root_div_main">
        <div id="mySidebar" className="customsidebar ">
          <a className="closebtn" onClick={() => closeNav()}>
            ×
          </a>

          <div className=" content">
            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {"Search"}
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by name"
                value={filterConfig.searchVal}
                onChange={(val) => {
                  const inputValue = val.target.value; // Trim leading and trailing whitespaces
                  //  console.log("inputValue",inputValue)
                  if (!inputValue.trim()) {
                    // console.log("inputValue2",inputValue)
                    if (filterConfig.searchVal.length == 1) {
                      // console.log("inputValue3",inputValue)
                      setFilterConfig({ ...filterConfig, searchVal: "" });
                    }

                    if (inputValue.length == 0) {
                      setFilterConfig({ ...filterConfig, searchVal: "" });
                    }
                  } else {
                    // console.log("inputValue4",inputValue)
                    setFilterConfig({
                      ...filterConfig,
                      searchVal: inputValue,
                    });
                  }
                }}
              />
            </div>

            <div className="my-3 d-flex justify-content-end">
              <button
                onClick={() => clearFilter()}
                className="btn btn-sm btn-yellow "
              >
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {"Clear Filter"}
              </button>
            </div>
          </div>
        </div>
        <div className="Subcription_name">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="page-heading mb-0 fw600">Subcription</h1>
            <div>
              {/* <span
                className="cursor_pointer px-3 me-0 me-md-2"
                onClick={() => {
                  openNav();
                }}
              >
                <Menu size={18} className="me-1 p-0" />

                {"Filter Menu"}
              </span> */}
              <button
                onClick={() => {
                  setVisible(true);
                  setAction({ ...action, state: "create", data: {} });
                  reset();
                }}
                className="btn btn-blue"
              >
                <Plus size={18} className="me-1 p-0" />
                Create Subcription
              </button>
            </div>
          </div>

          <div className="card mt-3 border-0">
            <div className="card-body">
              <div className="entriesPerPage inter-bold d-flex justify-content-between align-items-center mb-3">
                <p className="mb-0">
                  Show
                  <select
                    className="mx-1"
                    defaultValue={"10"}
                    onChange={(e) => {
                      setEntriesPerPage(e.target.value);
                      fetchSubcriptionList(page, e.target.value);
                    }}
                  >
                    <option value={"10"}>10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  Entries
                </p>
              </div>

              {dataList.loading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : dataList.data.length == 0 ? (
                <Alert className="text-center" variant={"danger"}>
                  No Subcription Found
                </Alert>
              ) : (
                <div className="table-responsive">
                  <table className="table mt-2 table-striped">
                    <thead className="table-grey">
                      <tr >
                        <th scope="col">{"SrNo"}</th>
                        <th scope="col">{"Plan Name"}</th>
                        <th scope="col">{"Plan Tenure"}</th>

                        <th scope="col">{"Action"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataList.data.map((val, i) => (
                        <tr key={val.uuid}>
                          <td>{parseInt(i) + 1 + parseInt(srNo)}</td>
                          <td>{val.plan_name}</td>
                          <td>{val.plan_tenure}</td>
                          <td>
                            <>
                              <Tooltip id={"edit-tooltip" + i} place="top" />
                              <div
                                data-tooltip-id={"edit-tooltip" + i}
                                data-tooltip-content={"Edit Subcription"}
                                className="editIcon m-1 cursor_pointer"
                                onClick={() => {
                                  setVisible(true);
                                  setAction({
                                    ...action,
                                    state: "update",
                                    data: val,
                                  });
                                  setValue("plan_name", val.plan_name);
                                  setValue("plan_tenure", val.plan_tenure);
                                  if (Object.keys(val.plan_configuration).length > 0) {
                                    console.log("val.plan_configuration", val.plan_configuration);
                                    let obj_key_arr = Object.keys(val.plan_configuration[0]);
                                    let obj_val_arr = Object.values(val.plan_configuration[0]);
                                    
                                    let arr = obj_key_arr.map((item, ind) => {
                                      let obj = {
                                        key: "label",
                                        value: item,
                                        text_value:
                                          obj_val_arr[ind] !== true && obj_val_arr[ind] !== false
                                            ? obj_val_arr[ind]
                                            : null,
                                      };
                                      return obj;
                                    });
                            
                                    console.log("arr", arr);
                                    setTable_lables(arr);
                                  }
                                 
                                }}
                              >
                                <Pencil size={18} color="#fff" />
                              </div>
                            </>
                            <>
                              <Tooltip id={"del-tooltip" + i} place="top" />
                              <div
                                data-tooltip-id={"del-tooltip" + i}
                                data-tooltip-content={"Delete Subcription"}
                                className="deleteIcon m-1 cursor_pointer"
                                onClick={() => {
                                  delHardness(val);
                                }}
                              >
                                <Trash2 size={18} color="#fff" />
                              </div>
                            </>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {dataList.loading ? null : dataList.data?.length > 0 ? (
                <div className="mt-4 d-flex justify-content-between">
                  <p className="my-auto">{`Showing ${parseInt(srNo) + 1} to ${
                    dataList.data.length +
                    entriesPerPage * page -
                    entriesPerPage
                  } of ${dataList.totalRecords} entries`}</p>
                  <Pagination>
                    <Pagination.Prev
                      disabled={page === 1 ? true : false}
                      onClick={() => {
                        fetchSubcriptionList(
                          page - 1,
                          entriesPerPage,
                          parseInt(srNo) - parseInt(entriesPerPage)
                        );
                        setSrNo((prevC) =>
                          page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                        );
                        setPage(page - 1);
                      }}
                    >
                      {"Prev"}
                    </Pagination.Prev>

                    <Pagination.Item active>{page}</Pagination.Item>

                    <Pagination.Next
                      disabled={
                        page === dataList.maxPage ||
                        dataList.maxPage === 0 ||
                        entriesPerPage > dataList.data.length
                          ? true
                          : false
                      }
                      onClick={() => {
                        fetchSubcriptionList(
                          page + 1,
                          entriesPerPage,
                          parseInt(srNo) + parseInt(entriesPerPage)
                        );
                        setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                        setPage(page + 1);
                      }}
                    >
                      {"Next"}
                    </Pagination.Next>
                  </Pagination>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Subcription;
